import React, { useRef, useEffect } from 'react';

const ScrollableCheckbox = ({ id, label, name, onScrollToBottom, ...props }) => {
  const containerRef = useRef(null);
  const checkboxRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollTop + clientHeight >= scrollHeight) {
          onScrollToBottom(true);
        }
      }
    };

    if (checkboxRef?.current && checkboxRef?.current?.checked && label.length < 1000) {
      onScrollToBottom(true);
    }

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [onScrollToBottom, label.length]);

  return (
    <div
      ref={containerRef}
      className={`scrollable-checkbox-group-container ${label.length > 1000 ? 'max-h-[150px] overflow-auto' : 'h-auto'}`}
    >
      <div className="checkbox">
        <input id={id} type="checkbox" className="input" name={name} ref={checkboxRef} {...props} />
        <span className="checkmark"></span>
        <label
          htmlFor={id}
          className="ml-10 color-from-bg text-xs not-italic font-normal leading-5 font-montserrat normal-case tracking-normal w-full"
          dangerouslySetInnerHTML={{ __html: label || props.placeholder }}
        />
      </div>
    </div>
  );
};

export default ScrollableCheckbox;
