import { ModuleBase, Link, FormPopup, ResponsiveImage, HeadingTag } from 'components';
import { useRouter } from 'next/router';
import ArrowRight from 'assets/arrowRight.svg';
import { isColorDark } from 'utils';

const CTacontactFormModule = ({ data }) => {
  const router = useRouter();

  const addQueryParam = (params) => {
    router.replace(
      {
        query: { ...router.query, ...params },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <ModuleBase data={data}>
      <FormPopup form={data.formModule} id={data.moduleId} />
      <div
        className="container flex flex-col pt-16 md:pt-20 lg:pt-26 pb-16 md:pb-20 lg:pb-26 
      items-center lg:justify-between gap-8 lg:gap-16 font-montserrat lg:flex-row"
      >
        <div className="w-full sm:w-fit h-fit gap-0 grid grid-cols-2">
          {data.imageCTAs.map((imageCTA, index) => (
            <Link
              link={imageCTA.ctaLink}
              key={index}
              className={`col-span-1 row-span-1 ${data.imageCTAs.length === 3 ? 'last:col-span-2' : ''}`}
            >
              <div className="relative w-full grid sm:h-full items-center sm:min-h-[368px]">
                {imageCTA.image && (
                  <ResponsiveImage
                    image={imageCTA.image}
                    widths={{ xs: 300, md: 300, lg: 300 }}
                    heights={{ xs: 368, md: 368, lg: 368 }}
                    className={`h-full w-full object-cover sm:aspect-auto col-start-1 row-start-1 sm:max-h-[368px] ${data.imageCTAs.length === 3 && index === 2 ? 'aspect-[2/1]' : 'aspect-square'}`}
                    image-class="h-full w-full object-cover"
                  />
                )}

                <div className="absolute col-start-1 row-start-1 h-full w-full p-4 flex items-center justify-center">
                  <div
                    className="btn imageCta !h-fit justify-self-center w-fit text-center bg-black/50 px-5 !py-2 rounded-full font-montserrat text-brand-soft-white
                text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase"
                  >
                    <span className="font-montserrat inline text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase">
                      {imageCTA.title}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div className="flex flex-col justify-between w-full sm:w-[468px] lg:w-1/2 lg:p-10 font-montserrat font-normal">
          {data.heading && (
            <HeadingTag
              data={data.heading}
              className="font-didot color-from-bg whitespace-pre-wrap text-title-l not-italic font-normal leading-10 -tracking-headline-m-mobile w-fit text-left pb-6 md:text-title-xl md:leading-[44px] md:-tracking-headline-l-mobile lg:mx-0 lg:w-fit lg:text-[64px] lg:leading-[80px] lg:-tracking-headline-s [&_.indented]:ml-11"
            />
          )}
          {data.text && (
            <div
              className="color-from-bg description text-sm md:text-base leading-6 mt-1 md:mt-1.5 lg:mt-0 lg:-tracking-[0.14px]  max-w-[392px] lg:pb-[60px]"
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          )}
          {data.ctaLabel && (
            <button
              className={`btn border-black/25 mt-8 lg:mt-[13.6px] md:justify-start px-6 md:w-fit ${isColorDark(data.backgroundColour) ? 'secondary border-brand-soft-white ' : `primary ${data.backgroundColour === '#bcab5d' && 'hover:!border-brand-soft-white'}`}`}
              onClick={() => addQueryParam({ form: true, form_id: data.moduleId })}
            >
              <span>{data.ctaLabel.toLocaleUpperCase(router.locale)}</span>
              <ArrowRight />
            </button>
          )}
        </div>
      </div>
    </ModuleBase>
  );
};

export default CTacontactFormModule;
