import React, { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { ModuleBase, Link, Media, HeadingTag, PreHeading, TextRevealAnimation } from 'components';
import { MediaLightboxCarousel } from '@/components';
import ArrowRight from 'assets/arrowRight.svg';

const HeroCenterAlignedModule = ({ data }) => {
  const { t } = useTranslation('common');
  const media = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const openFullscreen = () => {
    media?.current?.openFullscreen();
  };

  return (
    <>
      <ModuleBase data={data} className="relative h-svh lg:h-screen overflow-hidden">
        {data.backgroundMedia && (
          <div className="absolute top-0 left-0 right-0 h-full w-full">
            <Media
              ref={media}
              media={data.backgroundMedia}
              widths={{ xs: 414, sm: 768, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
              heights={{ xs: 896, sm: 1024, md: 1024, lg: 768, xl: 768, xxl: 1440 }}
              className="h-full w-full object-cover"
              cover
              dataNotLazy
            />
          </div>
        )}

        <div
          className="absolute top-0 left-0 right-0 h-full w-full"
          style={{
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 73.62%)',
          }}
        />
        <div
          className="container relative w-full h-full flex flex-col items-center justify-center"
          onClick={openFullscreen}
        >
          {data.preHeading && !data.preHeadingIsH1 && (
            <TextRevealAnimation>
              <PreHeading
                className="text-center text-brand-soft-white flex items-center font-montserrat
              uppercase text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile
              lg:text-sm lg:tracking-button-reg mb-5 lg:mb-6"
              >
                <span>{data.preHeading}</span>
              </PreHeading>
            </TextRevealAnimation>
          )}

          <TextRevealAnimation>
            <HeadingTag
              data={{ htag: data.headingTitle.htag }}
              className="font-didot not-italic font-normal leading-normal text-brand-soft-white
                text-4xl -tracking-headline-l-mobile !text-center
                sm:text-headline-m sm:-tracking-headline-l-tablet sm:even:[&_p]:ml-[74px] sm:even:[&_p]:mt-3 sm:text-left
                lg:text-headline-l lg:-tracking-headline-l lg:even:[&_p]:ml-[41px] 
                "
              dangerouslySetInnerHTML={{
                __html: data.preHeading
                  ? !data.preHeadingIsH1
                    ? data.headingTitle?.heading
                    : `
                    <p>
                      <span class="text-center text-brand-soft-white flex items-center justify-center font-montserrat uppercase text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile lg:text-sm lg:tracking-button-reg mb-5 lg:mb-6">
                        ${data.preHeading}
                      </span>
                      <span class="font-didot not-italic font-normal leading-normal text-brand-soft-white text-4xl -tracking-headline-l-mobile !text-center sm:text-headline-m sm:-tracking-headline-l-tablet sm:even:[&_p]:ml-[74px] sm:even:[&_p]:mt-3 sm:text-left lg:text-headline-l lg:-tracking-headline-l lg:even:[&_p]:ml-[41px]">
                        ${data.headingTitle.heading?.replace(/<(p|h[0-6])>/g, '').replace(/<\/(p|h[0-6])>/g, '')}
                      </span>
                    </p>
                  `
                  : data.headingTitle.heading,
              }}
            />
          </TextRevealAnimation>

          {(data.primaryCTA || (data.mediaLightBox && data.mediaLightBox.props)) && (
            <div className="button-wrapper flex justify-start gap-4 mt-8">
              {data.primaryCTA && (
                <Link
                  className="btn secondary !backdrop-blur-0 !border-brand-soft-white/75 px-5 py-3 sm:px-6 sm:py-3.5 h-11 sm:h-13"
                  link={data.primaryCTA}
                >
                  <span className="text-xs sm:text-sm tracking-quote-reg-mobile sm:tracking-button-reg leading-4.5 sm:leading-5">
                    {data.primaryCTA.text}
                  </span>
                  <ArrowRight
                    className="!ml-7 !stroke-transparent hover:!stroke-transparent !fill-brand-soft-white hover:!fill-black"
                    role="presentation"
                  />
                </Link>
              )}

              {data.mediaLightBox && data.mediaLightBox.props && (
                <button
                  className="btn secondary px-5 py-3 sm:px-6 sm:py-3.5 h-11 sm:h-13 group"
                  onClick={() => setIsOpen('0')}
                >
                  <span className="text-xs sm:text-sm tracking-quote-reg-mobile sm:tracking-button-reg leading-4.5 sm:leading-5">
                    {t('general.$viewGallery')}
                  </span>
                  <ArrowRight
                    className="!ml-7 !stroke-transparent hover:!stroke-transparent !fill-brand-soft-white group-hover:!fill-black"
                    role="presentation"
                  />
                </button>
              )}
            </div>
          )}
        </div>
      </ModuleBase>

      <MediaLightboxCarousel
        className="media-light-box-carousel"
        moduleName={data.moduleName}
        backgroundColour="#000000"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        slides={data.mediaLightBox?.props.slides}
        imageWidths={{ xs: 332, sm: 332, md: 688, lg: 1016, xl: 1016, xxl: 1016 }}
        imageHeights={{ xs: 163, sm: 163, md: 328, lg: 485, xl: 485, xxl: 485 }}
        imageContainerWidthClassName="w-[332px] md:w-[688px] lg:w-[1016px]"
        imageContainerHeightClassName="h-[163px] md:h-[328px] lg:h-[485px]"
      />
    </>
  );
};

export default HeroCenterAlignedModule;
