import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Close from 'assets/close.svg';
import { useTranslation } from 'next-i18next';
import FormModule from '@/modules/FormModule';
import { ModalPortal } from '@/components';
import { isColorDark } from '@/utils';
// import { colorFromBgColor } from '@/utils';

const FormPopup = ({ form, id }) => {
  const [open, setOpen] = useState(false);
  const [closeDelay, setCloseDelay] = useState(false);
  const router = useRouter();
  const dark = isColorDark(form.backgroundColour);

  const { t } = useTranslation('common');

  useEffect(() => {
    if (router.query.form && router.query.form_id === id) {
      setOpen(true);
      setCloseDelay(true);
    } else {
      setOpen(false);
      setTimeout(() => {
        setCloseDelay(false);
      }, 500);
    }
  }, [router.query.form, router.query.form_id, id]);

  const onClose = () => {
    const query = { ...router.query };
    delete query.form;
    delete query.form_id;
    router.replace(
      {
        query,
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <ModalPortal>
      <div className={classNames('', open ? 'h-dvh lg:h-screen disable-scroll' : 'h-0')} />
      <div
        className={classNames(
          'bg-black fixed top-0 left-0 z-40 w-screen transition-opacity duration-500',
          open ? 'h-dvh lg:h-screen opacity-75' : 'h-0 opacity-0',
        )}
      />

      <div
        className={classNames(
          'fixed top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2 z-50 transition-opacity duration-500 w-screen flex justify-center items-center overflow-hidden',
          open ? 'opacity-1' : 'opacity-0',
          closeDelay ? 'max-h-[calc(100vh-40px)] lg:h-fit' : 'h-0',
        )}
        onClick={onClose}
      >
        <div
          className={` max-h-[calc(100vh-40px)] sm:h-fit w-11/12 md:w-4/5 rounded-contact relative flex flex-col justify-between items-center shrink-0 overflow-auto`}
          style={{ backgroundColor: form.backgroundColour }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button
            onClick={onClose}
            className={`btn absolute right-0 top-0 p-4 h-auto bg-transparent ${dark ? '[&_svg]:stroke-brand-soft-white [&_svg]:hover:stroke-brand-soft-white/75' : '[&_svg]:stroke-black [&_svg]:hover:stroke-black/75'}`}
            aria-label={t('general.$close')}
          >
            <Close className="stroke-black w-6 h-6" />
          </button>

          <div className="flex flex-col items-center w-full px-10 py-8 lg:py-12">
            {form && <FormModule data={form} isPopup={true} />}
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default FormPopup;
