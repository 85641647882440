import { React, useState } from 'react';
import classNames from 'classnames';

const CustomRadioGroupInput = ({ left, right, value, onToggle, controlCssClass }) => {
  const [leftState, setLeftState] = useState(value ?? true);

  const handleOptionChange = (value) => {
    if (onToggle) {
      setLeftState(value);
      onToggle(value);
    }
  };
  return (
    <div
      className={classNames(
        'cursor-pointer rounded-full px-8 md:px-6 lg:px-8 h-[56px] backdrop-blur-[63px] bg-[#FFFEF980] relative z-50',
        leftState ? 'text-right' : 'text-left',
        controlCssClass,
      )}
      onClick={() => handleOptionChange(!leftState)}
    >
      <span
        className={classNames(
          'z-0 btn small p-5 h-full bg-[#BCAB5D] rounded-full absolute top-0 text-sm font-semibold w-1/2',
          leftState ? 'left-0 text-left' : 'left-1/2 text-right',
        )}
      >
        {leftState ? left : right}
      </span>
      <span className="h-full inline-flex items-center">{leftState ? right : left}</span>
    </div>
  );
};

export default CustomRadioGroupInput;
