import { ModuleBase, ResponsiveImage, PreHeading, HeadingTag, Link } from 'components';
import ArrowRight from 'assets/arrowRight.svg';

const HeroCenterAlignedModule = ({ data }) => {
  const { headingTitle, preHeading, primaryCTA, secondaryCTA, preHeadingIsH1 } = data || {};

  return (
    <ModuleBase data={data} className="relative w-full h-[calc(100svh-94px)] sm:h-[calc(100svh-104px)]">
      <div className="absolute w-full h-full">
        <ResponsiveImage
          image={data.backgroundMedia}
          widths={{ xs: 640, sm: 768, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
          heights={{ xs: 1385, sm: 1024, md: 1024, lg: 768, xl: 768, xxl: 1440 }}
          dataNotLazy
        />
      </div>

      <div className="absolute left-0 bottom-0 w-full h-full gradient-bottom-to-top" />
      <div className="container relative flex flex-col justify-end items-center w-full h-full">
        <div className="text-wrapper flex flex-col items-center bottom-12 lg:bottom-14 w-full ">
          {preHeading && !preHeadingIsH1 && (
            <PreHeading
              className="pre-heading text-brand-soft-white font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase mb-4 md:mb-6
                            md:text-sm md:tracking-button-reg"
            >
              {preHeading}
            </PreHeading>
          )}

          {headingTitle && (
            <HeadingTag
              data={{ htag: headingTitle?.htag }}
              className="main-title text-center text-brand-soft-white font-didot italic font-normal mb-12 lg:mb-14 
              text-4xl  leading-10 -tracking-quote-reg-tablet
              sm:text-headline-m sm:leading-[60px] sm:-tracking-headline-l-tablet
              lg:text-headline-l lg:leading-normal lg:tracking-[-1.28px]"
              dangerouslySetInnerHTML={{
                __html: preHeading
                  ? !preHeadingIsH1
                    ? headingTitle.heading
                    : `
                      <span class="pre-heading block text-center text-brand-soft-white font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase mb-4 md:mb-6 md:text-sm md:tracking-button-reg">${preHeading}</span>
                      <span class="main-title block text-center">${headingTitle.heading?.replace(/<(p|h[0-6])>/g, '').replace(/<\/(p|h[0-6])>/g, '')}</span>
                    `
                  : headingTitle.heading,
              }}
            />
          )}
          {(primaryCTA || secondaryCTA) && (
            <div className="flex flex-col md:flex-row gap-4 md:gap-6 mb-10 lg:mb-16">
              {primaryCTA && (
                <Link className="btn primary-light" link={primaryCTA}>
                  <span>{primaryCTA.text}</span>
                  <ArrowRight className="!ml-6" role="presentation" />
                </Link>
              )}

              {secondaryCTA && (
                <Link className="btn secondary-reverse " link={secondaryCTA}>
                  <span>{secondaryCTA.text}</span>
                  <ArrowRight className="ml-6" role="presentation" />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </ModuleBase>
  );
};

export default HeroCenterAlignedModule;
