import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { ModuleBase, HeadingTag } from '@/components';
import { openLink, useLayoutEffect } from '@/utils';
import classnames from 'classnames';
import GolfCourse from 'assets/golf_course.svg';
import HoleDetails from '@/modules/HoleDetails';
import { useTranslation } from 'next-i18next';

const HoleByHoleModule = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [underlineStyle, setUnderlineStyle] = useState({});
  const { t } = useTranslation('common');

  const tabsContainer = useRef();
  const router = useRouter();

  useEffect(() => {
    if (data?.holes && activeIndex === null) {
      let newActiveIndex = null;
      const hash = router.asPath.split('#')[1];

      if (hash && hash.startsWith('t') && hash.length > 4) {
        const mid = hash.substring(1, 4);
        if (mid === data.moduleId.substring(0, 3)) {
          const tabIndex = hash.substring(4);
          if (!isNaN(tabIndex)) {
            newActiveIndex = Number(tabIndex);
          }
        }
      }

      if (newActiveIndex === null) {
        for (let i = 0; i < data.holes.length; i++) {
          newActiveIndex = i;
          break;
        }
      }
      console.log(newActiveIndex);
      setActiveIndex(newActiveIndex);
    }
  }, [data, router.asPath, activeIndex]);

  useEffect(() => {
    moveUnderline();
  }, [activeIndex]);

  useLayoutEffect(() => {
    window.addEventListener('load', moveUnderline);
    window.addEventListener('resize', () => {
      moveUnderline();
    });
    return () => window.removeEventListener('resize', moveUnderline);
  }, []);

  const moveUnderline = () => {
    setTimeout(() => {
      if (tabsContainer?.current?.children) {
        Array.from(tabsContainer.current.children).forEach((element) => {
          if (element && element.classList && element.classList.contains('active-tab')) {
            let elementHalfWidth = element.offsetLeft + element.offsetWidth / 2 - 7;
            setUnderlineStyle({
              left: `${elementHalfWidth}px`,
            });
          }
        });
      }

      const container = document.querySelector('.holeContainer');
      if (container) {
        const content = document.querySelector('.activeHoleDetails');
        container.style.height = content
          ? content.offsetHeight + 'px'
          : container.firstChild
            ? container.firstChild.offsetHeight + 'px'
            : '100vh';
      }
    }, 0);
  };

  const tabClick = (tab, index) => {
    if (tab.tabLink) {
      openLink(router, tab.tabLink);
    } else {
      router.replace({
        hash: `t${data.moduleId.substring(0, 3)}${index}`,
      });
      setActiveIndex(index);
    }
  };

  return (
    <ModuleBase data={data} className="bg-background-primary !pb-0">
      <div className="h-fit">
        <div className="flex flex-col overflow-x-auto overflow-y-hidden scrollbar-hide font-montserrat ">
          <div className="flex flex-col lg:flex-row lg:items-center relative container pb-10 md:pb-16 lg:pb-24 after:content-[''] after:absolute after:bottom-0 after:h-px after:w-screen after:left-1/2 after:-translate-x-1/2 after:bg-[#76908080]">
            {data.heading && (
              <HeadingTag
                data={data.heading}
                className="w-2/5 font-didot color-from-bg whitespace-pre-wrap text-title-l not-italic font-normal leading-10 -tracking-headline-m-mobile
                            text-left md:text-title-xl md:leading-[44px] md:-tracking-headline-l-mobile
                            lg:text-[56px] lg:leading-[56px] lg:-tracking-headline-s [&_.indented]:ml-11"
              />
            )}
            {data.text && (
              <div
                className="h-fit lg:w-3/5 color-from-bg opacity-75 description text-sm md:text-base font-semibold leading-6 md:leading-[26px] mt-10 lg:mt-0 lg:-tracking-[0.14px]"
                dangerouslySetInnerHTML={{ __html: data.text }}
              />
            )}
          </div>
          {data.holes && (
            <div className={classnames('flex md:w-full xxl:container xxl:p-0')}>
              <div className="max-w-[220px] hidden lg:flex flex-col grow items-start lg:justify-center border-r border-[#76908080] p-4 lg:pl-16 lg:pr-[82px] md:py-7 text-center font-montserrat lg:whitespace-normal font-bold uppercase text-xl !leading-normal tracking-quote-reg-mobile md:tracking-button-reg text-brand-black">
                <GolfCourse />
                <span> {t('holeByHole.$holes')}</span>
              </div>
              <div
                ref={tabsContainer}
                className="relative flex md:w-full overflow-x-auto scroll-smooth xl:justify-center lg:px-16 snap-mandatory"
                style={{ mask: 'linear-gradient(90deg, white calc(100% - 132px), transparent);' }}
              >
                {data.holes.map((tab, index) => (
                  <div
                    key={index}
                    className={classnames(
                      'flex flex-col grow cursor-pointer items-center lg:justify-center whitespace-nowrap py-6 md:py-10 text-center font-montserrat lg:whitespace-normal',
                      'font-semibold uppercase text-sm !leading-normal tracking-quote-reg-mobile md:tracking-button-reg',
                      'snap-start shrink-0 w-20 md:max-w-[62px] lg:max-w-[62px] xl:max-w-[62px] ',
                      activeIndex === index ? 'active-tab text-brand-black' : 'text-[#76908080]',
                    )}
                    onClick={() => tabClick(tab, index)}
                    role="button"
                  >
                    <div>
                      <span className={classnames(activeIndex !== index ? 'mb-2.5' : '')}>
                        {t('holeByHole.$holePrefix')}
                        {index + 1}
                      </span>
                      <div
                        className={classnames(
                          'h-[38px] w-[38px] rounded-full mt-2.5 p-[12px]',
                          activeIndex === index ? 'bg-[#475A4F]' : 'bg-[#76908040]',
                        )}
                      >
                        {activeIndex === index && (
                          <div
                            className="holeByHoleGolfBall absolute top-50 transition-all duration-500 ease-in-out h-[14px] w-[14px] bg-[#FFFEF9] block rounded-full "
                            style={underlineStyle}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="holeContainer relative md:overflow-hidden w-screen">
          {data.holes &&
            data.holes.map((tab, index) => (
              <div
                key={index}
                className={classnames(
                  ' absolute w-full top-0 left-0 pb-0 pt-0 transition-all duration-700 ease-in-out opacity-1 ',
                  activeIndex === index ? 'activeHoleDetails opacity-1' : ' opacity-0',
                )}
              >
                <HoleDetails data={tab} active={activeIndex === index}></HoleDetails>
              </div>
            ))}
        </div>
      </div>
    </ModuleBase>
  );
};

export default HoleByHoleModule;
