module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}', './modules/**/*.{js,ts,jsx,tsx}'],
  theme: {
    languages: ['de', 'ru', 'tr'],
    container: {
      center: true,
      padding: {
        DEFAULT: '1.5rem',
        md: '2.5rem',
        xl: '6.75rem',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1536px',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000000',
      white: '#ffffff',

      grey1: '#323232',
      grey2: '#505050',
      grey3: '#828282',
      grey4: '#BEBEBE',
      grey5: '#DCDCDC',
      grey6: '#F0F0F0',

      'brand-soft-white': '#FFFEF9',
      'brand-black': '#000000',
      'background-secondary': '#C6CEBC',
      'background-card': '#F3F4EE',
      'opacity-black': '#000000',
      'semantic-error': '#BF1919',
      'semantic-link': '#3FA2F7',
      'light-green': '#D8E2C9',
      'logo-gold': '#D2AB67',
      error: ' #ff7878',
    },
    extend: {
      screens: {
        minheight800: { raw: '(min-height: 800px)' },
      },

      animation: {
        grow: 'grow 0.7s forwards',
        shrink: 'shrink 0.5s forwards',
      },
      keyframes: {
        grow: {
          '0%': { transform: 'scale(0)' },
          '50%': { transform: 'scale(1.2)' },
          '100%': { transform: 'scale(1)' },
        },
        shrink: {
          '0%': { transform: 'scale(1)' },
          '100%': { transform: 'scale(0)' },
        },
      },
      borderRadius: {
        contact: '3.4px',
      },
      backdropBlur: {
        btn: '42px',
      },
      content: {
        externalLink: 'url("../assets/externalLink.svg")',
        phone: 'url("../assets/phone.svg")',
        mail: 'url("../assets/mail.svg")',
        instagram: 'url("../assets/instaSlim.svg")',
      },
      spacing: {
        7.5: '1.875rem',
        26: '6.5rem',
      },
      letterSpacing: {
        'headline-l': '0.64px',
        'headline-l-tablet': '0.56px',
        'headline-l-mobile': '0.36px',
        'headline-m-tablet': '0.48px',
        'headline-m-mobile': '0.32px',
        'headline-s': '0.4px',
        'title-m': '0.6px',
        'title-s': '0.42px',
        'quote-reg': '0.96px',
        'quote-reg-tablet': '0.72px',
        'quote-reg-mobile': '0.6px',
        'quote-author': '0.16px',
        'card-m': '0.28px',
        'card-s': '0.24px',
        'card-xs': '0.2px',
        'button-reg': '0.7px',
        contact: '0.12px',
      },
      transitionProperty: {
        width: 'width',
        'backdrop-filter': 'backdrop-filter',
      },
      height: {
        13: '3.25rem',
      },
      margin: {},
      aspectRatio: {
        '4/3': '4 / 3',
        '3/4': '3 / 4',
      },

      fontFamily: {
        didot: ['Didot', 'Georgia', 'Times New Roman', 'Baskerville', 'ui-serif', 'system-ui', '-apple-system'],
        montserrat: [
          'Montserrat',
          'Helvetica',
          'Arial',
          'Tahoma',
          'Verdana',
          'ui-sans-serif',
          'system-ui',
          '-apple-system',
        ],
      },

      lineHeight: {
        normal: 'normal',
        3.5: '0.875rem', // 14px
        4.5: '1.125rem', // 18px
        6.5: '1.625rem', // 26px
        12: '3rem', //48px
        14: '3.5rem', // 56px
        'title-s': '17.07px', // 56px
      },

      fontSize: {
        'title-m': '1.75rem', // 28px
        'title-l': '2rem', // 32px
        'title-xl': '2.5rem', // 40px
        'headline-m': '3.5rem', //56px
        'headline-l': '4rem', // 64px
      },
    },
  },
  plugins: [
    require('tailwindcss-themer')({
      defaultTheme: {
        extend: {
          colors: {
            'brand-green': '#769080',
            'background-primary': '#D6DBD0',
            'background-spotlight': '#475A4F',
          },
        },
      },
      themes: [
        {
          name: 'Gold',
          selectors: ['.Gold', '[data-theme="Gold"]'],
          extend: {
            colors: {
              'brand-green': '#BCAB5D',
              'background-primary': '#E4DDBE',
              'background-spotlight': '#BCAB5D',
            },
          },
        },
      ],
    }),
    require('tailwind-scrollbar-hide'),
    require('@ganmahmud/tailwindcss-language-variant'),
  ],
};
