import React, { useState, useContext, useRef } from 'react';
import FormControl from './FormControl';
import classnames from 'classnames';
import { Formik, Field } from 'formik';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import SmootherContext from '../general/SmootherContext';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { format, endOfDay } from 'date-fns';

const UmbracoForm = ({ formDefinition, setHeadingData, onFinish, ...props }) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const form = useRef();
  const { t } = useTranslation('common');
  const scrollSmoother = useContext(SmootherContext);
  const router = useRouter();

  if (!formDefinition || !formDefinition.pages) {
    return null;
  }

  // console.log(formDefinition, 'formDefinition in umbracoform');
  // console.log(formDefinition.cssClass, 'formDefinition in umbracoform');
  // console.log(formDefinition.name, 'formDefinition in umbracoform');

  const initialValues = {};
  formDefinition?.pages.forEach((page) =>
    page.fieldsets.forEach((fieldset) =>
      fieldset.columns.forEach((column) =>
        column.fields.forEach((uField) => {
          // console.log(uField, 'uField');
          if (uField.type.name === 'Multiple choice') {
            initialValues[uField.alias] = [uField.settings.defaultValue];
          } else {
            initialValues[uField.alias] = uField.settings.defaultValue;
          }
        }),
      ),
    ),
  );

  const getActivePageFields = () => {
    const fields = [];
    if (formDefinition?.pages[activePageIndex]) {
      formDefinition?.pages[activePageIndex].fieldsets.forEach((fieldset) =>
        fieldset.columns.forEach((column) => column.fields.forEach((uField) => fields.push(uField))),
      );
    }
    return fields;
  };

  const formDefinitionName = formDefinition.name.replace(/[\W_]+/g, '').toLowerCase();

  const nameFix = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : null;
  };

  const validate = (values) => {
    const errors = {};

    if (formDefinition?.pages[activePageIndex]) {
      const fields = getActivePageFields();
      fields.forEach((field) => {
        if (field.required) {
          if (['checkbox', 'Data Consent', 'scrollableCheckbox'].includes(field.type.name)) {
            if (!values[field.alias]) {
              errors[field.alias] = t('forms.$mandatoryMessage', {
                fieldName: t(`forms.${formDefinitionName}.$${formDefinitionName}${nameFix(field?.alias)}`),
              });
            }
          } else if (!values[field.alias] || !values[field.alias]?.trim()) {
            errors[field.alias] = t('forms.$mandatoryMessage', {
              fieldName: t(`forms.${formDefinitionName}.$${formDefinitionName}${nameFix(field?.alias)}`),
            });
          }
        }
        if (field.pattern && !errors[field.alias]) {
          const re = new RegExp(field.pattern);
          if (!re.test(values[field.alias]?.trim())) {
            errors[field.alias] = t(
              `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(field?.alias)}PatternError`,
            );
          }
        }

        if (
          field.alias === 'reEnterEmailAddress' &&
          !errors[field.alias] &&
          values.reEnterEmailAddress?.toLowerCase() !== values?.emailAddress.toLowerCase()
        ) {
          errors[field.alias] = t(
            `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(nameFix(field?.alias))}MatchError`,
          );
        }
      });
    }

    setTimeout(() => {
      const element = form.current.querySelector('.input-group.error');
      if (element && !ScrollTrigger.isInViewport(element)) {
        scrollSmoother.scrollTo(element, true, 'top 100px');
      }
    }, 0);

    return errors;
  };

  const next = (errors, setTouched) => {
    if (Object.keys(errors).length === 0) {
      setActivePageIndex(activePageIndex + 1);
    } else {
      const fields = getActivePageFields();
      const touched = {};
      fields.forEach((field) => {
        touched[field.alias] = true;
      });
      setTouched(touched);
    }
  };

  const prev = () => {
    setActivePageIndex(activePageIndex - 1);
  };

  const fixValues = (values) => {
    const fixedValues = {};
    for (const [key, value] of Object.entries(values)) {
      let newValue = value;
      if (key === 'domainAddress') {
        newValue = window.location.href;
      } else if (key === 'language' && value === '') {
        newValue = router.locale === 'en-US' ? 'en' : router.locale;
      } else if (typeof value == 'boolean') {
        newValue = `${value}`;
      } else if (value && typeof value.getMonth === 'function') {
        newValue = format(endOfDay(value), "yyyy-MM-dd'T'HH:mm:ss'Z'");
      }

      fixedValues[key] = newValue || '';
    }
    return fixedValues;
  };

  const submit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    const submitRes = await fetch(`/api/umbraco/forms/api/v1/entries/${formDefinition.id}`, {
      method: 'POST',
      body: JSON.stringify({ values: fixValues(values) }),
    }).catch(console.error);

    if (submitRes.ok) {
      setSubmitted(true);
      setHeadingData({
        preHeading: t('general.$success'),
        heading: t(`forms.${formDefinitionName}.$${formDefinitionName}SuccessHeading`),
        text: t(`forms.${formDefinitionName}.$${formDefinitionName}SuccessMessage`),
        cta: { link: '/', text: t('general.$returnToHomepage') },
      });
    } else {
      setSubmitted(true);
      const errorMessage = await submitRes.text();
      console.log(`Post Error. HTTP Response Code: ${submitRes?.status} ErrorMessage: ${errorMessage}`);
      setHeadingData({
        preHeading: t('general.$error'),
        errorHeading: t(`forms.${formDefinitionName}.$${formDefinitionName}ErrorHeading`),
      });
    }
    onFinish && onFinish();
  };

  const handleScrollToBottom = (isBottom) => {
    if (isBottom) {
      setScrolledToBottom(true);
    }
  };

  return (
    <div {...props}>
      {submitted ? (
        <div />
      ) : (
        <Formik initialValues={initialValues} validate={validate} onSubmit={submit}>
          {({ handleSubmit, isSubmitting, validateForm, setTouched }) => (
            <form ref={form} onSubmit={handleSubmit} className="form">
              <div className="flex">
                {formDefinition.pages &&
                  formDefinition.pages.map((page, pageIndex) => (
                    <div
                      key={pageIndex}
                      className={classnames(
                        'page-container w-full flex-col gap-12 sm:gap-8 lg:gap-10',
                        activePageIndex === pageIndex ? 'flex' : 'hidden',
                      )}
                    >
                      {page.fieldsets &&
                        page.fieldsets.map((fieldset) => (
                          <fieldset
                            key={fieldset.id}
                            className={classNames(
                              'fieldset flex flex-col sm:flex-row gap-12 sm:gap-8 lg:gap-12',
                              fieldset.caption ? fieldset.caption + '-fieldset' : null,
                            )}
                          >
                            {fieldset.columns &&
                              fieldset.columns.map((column, columnIndex) => (
                                <div key={columnIndex} className="flex w-full flex-col  fieldset-column">
                                  {column.fields &&
                                    column.fields.map((uField) => {
                                      return (
                                        <Field key={uField.id} name={uField?.alias}>
                                          {({
                                            field, // { name, value, onChange, onBlur }
                                            // form: { touched, errors, dirty }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                            meta,
                                          }) => {
                                            return (
                                              <FormControl
                                                id={uField.id}
                                                type={uField?.type?.name}
                                                label={
                                                  uField?.settings?.showLabel === 'True' &&
                                                  (uField?.caption
                                                    ? t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Label`,
                                                      ) +
                                                      t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Label2`,
                                                        {
                                                          defaultValue: '',
                                                        },
                                                      ) +
                                                      t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Label3`,
                                                        {
                                                          defaultValue: '',
                                                        },
                                                      ) +
                                                      t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Label4`,
                                                        {
                                                          defaultValue: '',
                                                        },
                                                      ) +
                                                      t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Label5`,
                                                        {
                                                          defaultValue: '',
                                                        },
                                                      ) +
                                                      t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Label6`,
                                                        {
                                                          defaultValue: '',
                                                        },
                                                      ) +
                                                      t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Label7`,
                                                        {
                                                          defaultValue: '',
                                                        },
                                                      )
                                                    : null)
                                                }
                                                error={meta.touched && meta.error}
                                                maxLength={uField?.settings?.maximumLength}
                                                placeholder={
                                                  uField?.settings?.placeholder
                                                    ? t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Placeholder`,
                                                      )
                                                    : null
                                                }
                                                settings={uField?.settings}
                                                options={uField?.preValues.map(({ caption, value }) => ({
                                                  label: caption,
                                                  value,
                                                }))}
                                                required={uField?.required}
                                                disabled={isSubmitting}
                                                selectPrompt={
                                                  uField?.settings?.selectPrompt
                                                    ? t(
                                                        `forms.${formDefinitionName}.$${formDefinitionName}${nameFix(
                                                          uField?.alias,
                                                        )}Placeholder`,
                                                      )
                                                    : null
                                                }
                                                formDefinitionName={formDefinitionName}
                                                {...field}
                                                onChildScrollToBottom={handleScrollToBottom}
                                              />
                                            );
                                          }}
                                        </Field>
                                      );
                                    })}
                                </div>
                              ))}
                          </fieldset>
                        ))}
                    </div>
                  ))}
              </div>

              <div className="btn-container flex justify-center mt-4">
                {activePageIndex !== 0 && (
                  <button className="btn secondary first:mr-4 only:mr-0" onClick={prev}>
                    {formDefinition.previousLabel}
                  </button>
                )}
                {activePageIndex === formDefinition.pages.length - 1 ? (
                  <input
                    type="submit"
                    className={`btn secondary !px-12 ${!scrolledToBottom ? 'pointer-events-none !bg-grey4' : 'pointer-events-auto'}`}
                    value={t(`forms.${formDefinitionName}.$${formDefinitionName}SubmitButton`)}
                    disabled={isSubmitting}
                  />
                ) : (
                  <div
                    type="button"
                    className={`btn secondary !px-12 ${!scrolledToBottom ? 'pointer-events-none !bg-grey4' : 'pointer-events-auto'}`}
                    onClick={() => validateForm().then((errors) => next(errors, setTouched))}
                  >
                    {formDefinition.nextLabel}
                  </div>
                )}
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UmbracoForm;
