import Head from 'next/head';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectHost, selectProtocol } from '@/store/index';

const Metadata = ({ metadata }) => {
  const host = useSelector(selectHost);
  const protocol = useSelector(selectProtocol);

  if (!metadata) return;

  const vanillaScripts = metadata.headSectionScripts
    .map((s) => {
      if (!s || s === '') return null;
      return s.replace(/<script[^>]*>/g, '').replace(/<\/script>/g, '');
    })
    .join('\n');

  const scriptSrcs = [];
  metadata.headSectionScripts.forEach((s) => {
    if (s && s !== '') {
      const match = /src\s*=\s*"([^"]+)/g.exec(s);
      if (match) {
        scriptSrcs.push(...match.filter((res) => !res.startsWith('src=')));
      }
    }
  });

  const hreflangs = [];

  if (metadata.languages && metadata.languages.length > 0) {
    let currentHost = `${protocol}//${host}`;

    metadata.languages.forEach((language, index) => {
      if (language.language === 'en-us') {
        hreflangs.push(
          <link rel="alternate" key={index} href={currentHost + language.languageUrl} hrefLang="x-default" />,
        );
      }

      hreflangs.push(
        <link
          rel="alternate"
          key={index + 1}
          href={currentHost + language.languageUrl}
          hrefLang={language.language === 'en-us' ? 'en' : language.language}
        />,
      );
    });
  }

  return (
    <Head>
      <title>{metadata.pageTitle}</title>
      <meta name="keywords" content={metadata.metaKeywords} />
      <meta name="description" content={metadata.metaDescription} />
      <meta property="og:url" content={metadata.absolutePageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metadata.openGraphPageTitle} />
      <meta property="og:description" content={metadata.openGraphDescription} />
      <meta property="og:image" content={metadata.openGraphImageUrl} />
      <meta name="google-site-verification" content="_yUBe5g70dUd9mdrdruRZI7B6dJFLek5poJW6N83pjY" />
      <link rel="canonical" href={metadata.canonicalLink} />

      {...hreflangs}

      {metadata.hideFromSearch && <meta name="robots" content="noindex" />}

      {scriptSrcs.map((script, index) => (
        <script key={index} src={script} defer />
      ))}

      {metadata.headSectionScripts && metadata.headSectionScripts.length > 0 && (
        <script
          dangerouslySetInnerHTML={{
            __html: vanillaScripts,
          }}
        />
      )}

      {metadata.structuredDataTags.map((element, index) => (
        <script key={index} type="application/ld+json" id="structuredDataTags">
          {element}
        </script>
      ))}

      {metadata.favicon?.imageUrl && <link rel="icon" type="image/png" href={metadata.favicon.imageUrl} />}
    </Head>
  );
};

Metadata.propTypes = {
  metadata: PropTypes.object.isRequired,
};

export default Metadata;
