import { useState, useEffect } from 'react';
import Pause from 'assets/pause.svg';
import Play from 'assets/play.svg';

const HoleByHoleVideoControll = ({ videoRef }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [dayVideoDuration, setDayVideoDuration] = useState(0);
  const [dayVideoCurrentTime, setDayVideoCurrentTime] = useState(0);

  useEffect(() => {
    getDuration();
    getCurrentTime();
    const dayInterval = setInterval(getCurrentTime, 1000);
    return () => {
      clearInterval(dayInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef]);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const getCurrentTime = () => {
    videoRef.current?.getCurrentTime()?.then((currentTime) => {
      setDayVideoCurrentTime(toHHMMSS(currentTime));
    });
  };

  const getDuration = () => {
    videoRef.current?.getDuration()?.then((duration) => {
      setDayVideoDuration(toHHMMSS(duration));
    });
  };

  const toHHMMSS = (sec) => {
    const secNum = parseInt(sec, 10); // don't forget the second param
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    let minutesString = minutes + '';
    let secondsString = seconds + '';

    if (seconds < 10) {
      secondsString = '0' + seconds;
    }
    return minutesString + ':' + secondsString;
  };

  return (
    <div className="absolute bottom-6 md:bottom-10 lg:bottom-16 left-0 z-50 flex w-full md:w-fit justify-between items-center gradient-left-to-right gap-4 pl-6 md:pl-10 lg:pl-16 pr-12 text-sm font-semibold uppercase">
      <button
        className="z-50 btn small p-0 w-14 h-14 bg-brand-soft-white/10 text-xs md:text-sm rounded-full backdrop-blur-[50px] flex items-center justify-center"
        onClick={handlePlayPause}
      >
        {isPlaying ? <Pause /> : <Play className="fill-brand-soft-white w-[22px]" />}
      </button>
      <div className="text-sm font-semibold leading-title-s tracking-headline-m-tablet text-left text-brand-soft-white tabular-nums">
        {dayVideoCurrentTime} / {dayVideoDuration}
      </div>
    </div>
  );
};

export default HoleByHoleVideoControll;
