import React, { useEffect, useState, useRef } from 'react';
import { Media, ResponsiveImage, CustomRadioGroupInput } from 'components';
import classNames from 'classnames';
//import tailwindConfig from 'tailwind.config.js';
import Expand from 'assets/expand.svg';
import Collapse from 'assets/collapse.svg';
import NightMode from 'assets/dark_mode.svg';
import DayMode from 'assets/wb_sunny.svg';
import HoleByHoleVideoControll from '../components/general/HoleByHoleVideoControll';

import { useTranslation } from 'next-i18next';

const HoleDetails = ({ data, active }) => {
  // const [showVideo, setShowVideo] = useState(0);
  const [infoPanel, setInfoPanel] = useState(true);
  const [showImage, setShowImage] = useState(true);
  const [showDayMode, setShowDayMode] = useState(true);
  const { t } = useTranslation('common');
  const dayMedia = useRef();
  const nightMedia = useRef();

  //const media = useRef();

  useEffect(() => {
    // const onResize = () => {
    //   setShowVideo(window.innerWidth >= parseInt(tailwindConfig.theme.screens.sm, 10));
    // };
    // onResize();
    setInfoPanel(true);
    // window.addEventListener('resize', onResize);
    // return () => {
    //   window.removeEventListener('resize', onResize);
    // };
    //var dayInterval = setInterval(getDayVideoCurrentTime, 1000);
    return () => {
      // clearInterval(dayInterval);
    };
  }, []);

  // const openFullscreen = () => {
  //   media?.current?.openFullscreen();
  // };
  const showMediaTypeToggle = () => {
    return (
      (showDayMode &&
        data.mediaItemDay?.coverImage &&
        (data.mediaItemDay?.videoFromGallery || data.mediaItemDay?.vimeoId)) ||
      (!showDayMode &&
        data.mediaItemNight?.coverImage &&
        (data.mediaItemNight?.videoFromGallery || data.mediaItemNight?.vimeoId))
    );
  };

  const showDayNightToggle = () => {
    return data.mediaItemDay.coverImage && data.mediaItemNight.coverImage;
  };

  const close = () => {
    setInfoPanel(!infoPanel);
  };

  const toggleMediaType = () => {
    setShowImage(!showImage);
  };

  const toggleDayNight = () => {
    setShowDayMode(!showDayMode);
  };

  return (
    <div data={data} className="font-montserrat text-brand-black relative h-fit flex flex-col">
      <div className="absolute top-6 md:top-10 lg:top-16 left-0 z-50 flex flex-row w-full md:w-fit justify-between gradient-left-to-right gap-4 px-6 md:px-10 lg:pr-0 lg:pl-16 text-sm font-semibold uppercase">
        {showMediaTypeToggle() && (
          <CustomRadioGroupInput
            left={t('holeByHole.$photo')}
            right={t('holeByHole.$video')}
            value={showImage}
            onToggle={toggleMediaType}
            controlCssClass="w-[200px] md:w-[170px] lg:w-[200px] h-[56px] md:h-[46px] lg:h-[56px]"
          ></CustomRadioGroupInput>
        )}
        {showDayNightToggle() && (
          <CustomRadioGroupInput
            left={<DayMode />}
            right={<NightMode />}
            value={showDayMode}
            onToggle={toggleDayNight}
            controlCssClass="w-[144px] md:w-[120px] lg:w-[144px] h-[56px] md:h-[46px] lg:h-[56px]"
          ></CustomRadioGroupInput>
        )}
      </div>

      <div className="relative">
        <div
          className={classNames(
            'relative lg:absolute lg:top-0 lg:left-0 w-full transition-all duration-1000 h-[320px] md:h-[432px] md:aspect-video lg:aspect-auto lg:h-[700px] xl:h-[800px]',
            showDayMode ? 'opacity-1' : 'opacity-0',
          )}
        >
          <ResponsiveImage
            image={data.mediaItemDay.coverImage}
            widths={{ xs: 640, sm: 640, md: 1024, lg: 1024, xl: 1440, xxl: 2560 }}
            heights={{ xs: 320, sm: 432, md: 700, lg: 928, xl: 800, xxl: 1440 }}
            className={classNames('absolute top-0 left-0 object-cover transition-all duration-1000 ease-in-out')}
          />
          {active && (
            <>
              <Media
                ref={dayMedia}
                media={{
                  coverImage: data.mediaItemDay.coverImage,
                  vimeoId: data.mediaItemDay?.vimeoId,
                  mobileVimeoId: data.mediaItemDay?.mobileVimeoId,
                }}
                controls={false}
                cover
                className={classNames(
                  'absolute top-0 left-0 h-full w-full object-cover transition-all duration-1000 ease-in-out',
                  showImage ? 'opacity-0' : 'opacity-1',
                )}
              />
              {!showImage && showMediaTypeToggle() && <HoleByHoleVideoControll videoRef={dayMedia} />}
            </>
          )}
        </div>
        <div
          className={classNames(
            'absolute top-0 left-0 right-0 w-full transition-all duration-1000 h-[320px] md:h-[432px] md:aspect-video lg:aspect-auto lg:h-[700px] xl:h-[800px]',
            showDayMode ? 'opacity-0' : 'opacity-1',
          )}
        >
          <ResponsiveImage
            image={data.mediaItemNight.coverImage}
            widths={{ xs: 640, sm: 640, md: 1024, lg: 1024, xl: 1440, xxl: 2560 }}
            heights={{ xs: 320, sm: 432, md: 700, lg: 928, xl: 800, xxl: 1440 }}
            className={classNames(
              'absolute top-0 left-0 object-cover transition-all duration-1000',
              showImage ? 'opacity-1' : 'opacity-0',
            )}
          />
          {active && (
            <>
              <Media
                ref={nightMedia}
                media={{
                  coverImage: data.mediaItemNight.coverImage,
                  vimeoId: data.mediaItemNight?.vimeoId,
                  mobileVimeoId: data.mediaItemNight?.mobileVimeoId,
                }}
                controls={false}
                cover
                className={classNames(
                  'absolute top-0 left-0 h-full w-full object-cover transition-all duration-1000',
                  showImage ? 'opacity-0' : 'opacity-1',
                )}
              />
              {!showImage && showMediaTypeToggle() && <HoleByHoleVideoControll videoRef={nightMedia} />}
            </>
          )}
        </div>
      </div>

      <div className="w-full h-full relative lg:h-screen lg:max-h-[800px]">
        <div className="w-full mx-auto h-full flex content-end justify-end flex-wrap">
          <div className="relative lg:mr-16 w-full h-full lg:w-[512px] p-0 lg:py-16">
            <button
              className="z-50 btn small h-14 absolute right-0 top-16 lg:p-5 bg-[#BCAB5D] text-xs leading-[14px] font-semibold hidden lg:flex"
              onClick={() => {
                close();
              }}
            >
              <span>{infoPanel ? t('holeByHole.$hideInfo') : t('holeByHole.$showInfo')}</span>
              {infoPanel ? <Collapse /> : <Expand />}
            </button>
            <div
              className={classNames(
                'flex flex-col justify-end gap-y-6 backdrop-blur-[50px] bg-[#BCAB5D] lg:bg-[#FFFEF980] px-6 py-10 md:p-10 font-semibold',
                'w-full h-full md:h-fit transition-all duration-700 origin-top-right',
                infoPanel ? 'scale-1 opacity-1' : 'scale-0 opacity-0',
              )}
              onClick={(e) => e.stopPropagation()}
            >
              {data.holeName && (
                <div className="transition-colors lg:whitespace-normal uppercase text-xl md:text-lg lg:text-xl !leading-normal tracking-quote-reg-mobile md:tracking-button-reg">
                  {data.holeName}
                </div>
              )}
              <div
                className={classNames(
                  'flex flex-col justify-between gap-y-6 h-fit md:grid md:grid-cols-6 lg:flex',
                  infoPanel ? 'block' : 'hidden',
                )}
              >
                <div className="order-2 lg:order-1 md:col-start-5 md:col-span-2 md:pl-20 lg:pl-0 flex md:justify-end">
                  <ResponsiveImage
                    image={data.holeMap}
                    heights={{ xs: 120, md: 120, lg: 120, xl: 120 }}
                    widths={{ xs: 190, md: 190, lg: 190, xl: 190 }}
                    imgProps={{
                      className: 'w-1/2 md:w-full lg:w-1/2 object-contain',
                      width: '190',
                      alt: 'map',
                    }}
                    dataNotLazy
                  />
                </div>
                <div className="text-sm md:text-xs order-1 lg:order-2 md:col-span-4 lg:text-sm uppercase grid grid-cols-2 gap-x-6 gap-y-4">
                  {data.holeDetails.map((detail, index) => (
                    <div key={index} className="flex justify-between border-b border-black/10 pb-2.5">
                      <span>{detail.detailName}</span>
                      <span className="text-right">{detail.detailValue}</span>
                    </div>
                  ))}
                </div>
                {data.description && (
                  <div
                    className="w-full order-3 md:col-span-full color-from-bg description text-sm font-semibold leading-6 mt-4 lg:-tracking-[0.14px]"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoleDetails;
